import { graphql } from "gatsby";
import React from "react";
import { MainHeading } from "../components/MainHeading";
import { Page } from "../components/Page";

interface BandPageProps {
  data: GatsbyTypes.MembersQuery;
}

export default function BandPage({ data }: BandPageProps) {
  return (
    <Page title="Band">
      <div className="grid gap-y-6 mb-6">
        {data.allMembersJson.group.map((section) => (
          <div key={section.fieldValue}>
            <MainHeading>{section.fieldValue}</MainHeading>
            <ul className="grid md:grid-cols-2 xl:grid-cols-3 gap-x-6 list-none list-outside">
              {section.nodes.map((member) => (
                <li key={member.id}>
                  {member.name}&nbsp;
                  <span className="text-sm text-gray-400">
                    &nbsp;/&nbsp;{member.instruments?.join(", ")}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </Page>
  );
}

export const query = graphql`
  query Members {
    allMembersJson(
      filter: { band: { ne: "Brassroots" } }
      sort: { fields: name, order: ASC }
    ) {
      group(field: section) {
        fieldValue
        nodes {
          id
          name
          instruments
        }
      }
    }
  }
`;
